import { useRouter } from 'next/router';
import styles from './hero-section-image.module.scss';
import Animated from '@/components/animated';
import Text from '@/components/text';
import Button from '@/components/button';
import Image from '@/components/image';
import { ctaEvents } from '@/lib/gtm';

const HeroSectionImage = ({
	topBackgroundImage,
	title,
	primaryActionButton,
	secondaryActionButton,
	configuration,
}) => {
	const router = useRouter();
	const scrollDown = () => {
		scroll({
			top: window.innerHeight - 64, // 100vh minus navbar height
			behavior: 'smooth',
		});
	};
	return (
		<section className={styles.heroSection}>
			<Image image={topBackgroundImage} className={styles.topBackgroundImage} alt="Image" />
			<div className="container relative">
				<div>
					<Animated animation="slideInLeft">
						<Text tag="h1">{title}</Text>
					</Animated>
				</div>
				<Animated animation="slideInDown" delay={600}>
					<Button
						text={primaryActionButton.title}
						onClick={() => {
							ctaEvents(
								'hero',
								configuration?.wizardConfiguration?.diagnosisType,
								configuration?.wizardConfiguration?.defaultVisitType === 'Revisit',
							);
							sessionStorage.setItem('currentUrl', router.asPath);
							router.push({
								pathname: '/arivo',
								query: {
									...(configuration?.wizardConfiguration?.defaultVisitType ===
									'Revisit'
										? { revisit: true }
										: null),
								},
							});
						}}
					/>
				</Animated>
				{secondaryActionButton && (
					<div>
						<Animated animation="slideInDown" delay={800}>
							<div className="pt-5">
								<Button
									text={secondaryActionButton.title}
									onClick={() => {
										ctaEvents(
											'hero',
											configuration?.wizardConfiguration?.diagnosisType,
											'revisit',
										);
										sessionStorage.setItem('currentUrl', router.asPath);
										router.push({
											pathname: '/arivo',
											query: {
												revisit: true,
											},
										});
									}}
									type="secondary"
								/>
							</div>
						</Animated>
					</div>
				)}
			</div>
			<div className={styles.scrollDownButton}>
				<Button
					onClick={scrollDown}
					type="icon"
					icon="/icons/angle-down.svg"
					text="Scrolla ner"
				/>
			</div>
		</section>
	);
};

export default HeroSectionImage;
